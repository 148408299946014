<template>
    <footer :class="$style.appFooter">
        <b-container>
            <p :class="$style.text">Copyright &copy; 2021 - e.Mix</p>
        </b-container>
    </footer>
</template>

<script>

export default {

    name: 'AppFooter'
}

</script>

<style lang="scss" module>

.appFooter {

    text-align: center;

}

.text {

    border-top: 1px solid var(--separator-color);
    margin: 0;
    padding: 1rem 0;

}

</style>