<template>
    <header :class="$style.appHeader">
        
        <router-link to="/">
            <img :class="$style.appHeaderLogo" src="@/assets/logo-emix.svg" alt="e.Mix">
        </router-link>

        <AppName />

        <AppMenu />

        <AppUserSection />

    </header>
</template>

<script>

import AppMenu from './AppMenu'
import AppName from './AppName'
import AppUserSection from './AppUserSection'

export default {
    
    name: 'AppHeader',

    components: {
        AppMenu,
        AppName,
        AppUserSection,
    }

}

</script>

<style lang="scss" module>

@import '@/scss/component.scss';

.appHeader {

    align-items: center;
    background-color: #fff;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, .1);
    display: flex;
    height: $headerHeight;
    padding: 0 0 0 2rem;
    position: sticky;
    top: 0;
    z-index: 999;

}

.appHeaderLogo {

    border-right: 1px solid var(--separator-color);
    display: block;
    height: 24px;
    margin-right: 2rem;
    padding-right: 2rem;

}

</style>