<template>
    <div :class="$style.appUserSection">

        <a v-if="$store.getters.user" :class="$style.userData" @click="userProfile" href="javascript:void(0);">
            
            <b-icon :class="$style.icon" icon="person-circle" />
            
            <div>
                {{ $store.getters.user ? $store.getters.user.userName : 'Usuário não identificado' }}
                <small :class="$style.profile">{{ $store.getters.user && $store.getters.user.profile ? $store.getters.user.profile.profileName : 'Usuário não identificado' }}</small>
            </div>

        </a>

        <div v-if="!$store.getters.user" :class="$style.userData">
            <Loader text="" size="small" />
        </div>

        <menu :class="$style.menu">

            <a :class="$style.menuItem" v-on:click="passwordReset">
                <b-icon :class="$style.menuIcon" icon="key"></b-icon>
                <span :class="$style.menuText">Trocar Senha</span>
            </a>

            <router-link :class="$style.menuItem" to="/Logout">
                <b-icon :class="$style.menuIcon" icon="power"></b-icon>
                <span :class="$style.menuText">Logout</span>
            </router-link>

        </menu>

    </div>
</template>

<script>

import Loader from '@/components/Loader.vue'

export default {

    name: 'AppUserSection',

    components: {
        Loader
    },

    methods: {

        userProfile() {

            this.$router.push('/user/profile');

        },

        passwordReset() {

            let newPassword = ((length) => {
                
                let result = '';
                let characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
                let charactersLength = characters.length;
                
                for ( let i = 0; i < length; i++ )
                    result += characters.charAt(Math.floor(Math.random() * charactersLength));
                
                return result;

            }) (12);

            newPassword = prompt('Nova senha:', newPassword);

            if(newPassword == null)
                return;

            if(newPassword.length < 8 || newPassword.length > 15) {

                alert('Escolha uma senha entre 8 e 15 caracteres');
                return;

            }

            this.$store.commit('appLoadingOn');

            this.$http.post(`${this.$store.getters.api}/user/${this.$store.getters.user.userId}/new-password`, { newPassword: newPassword })
                .then((response) => {

                    alert(`Senha alterada com sucesso para "${response.data.data}".`);

                })
                .catch(() => {
                    alert('Falha ao alterar a senha!');
                })
                .finally(() => {
                    this.$store.commit('appLoadingOff');
                });

        }

    }

}

</script>

<style lang="scss" module>

@import '@/scss/component.scss';

.appUserSection {

    border-left: 1px solid var(--separator-color);
    cursor: pointer;
    margin-left: 1rem;
    height: $headerHeight;
    position: relative;
    transition: all .1s linear;

    .menu {

        display: none;

    }

    &:hover {

        background-color: var(--theme-color-2);
        color: #000;

        .menu {

            display: block;

        }

    }

}

a.userData {
    
    align-items: center;
    color: var(--font-color);
    display: flex;
    height: $headerHeight;
    padding: 0 1rem;

    &:hover {

        color: #000;
        text-decoration: none;

    }

}

.icon {

    font-size: 1.5rem;
    margin-right: .5rem;

}

.menu {

    background: #fff;
    border-bottom: 1px solid var(--separator-color);
    box-shadow: -3px 3px 3px 0px rgba(0, 0, 0, .05);
    margin: 0;
    padding: 0;
    position: absolute;
    right: 0;
    top: 100%;
    width: 200px;

}
a.menuItem {

    border-top: 1px solid var(--separator-color);
    display: block;
    padding: .7rem 1rem;

     &:hover {

        background-color: var(--theme-color-2);
        color: #000;
        text-decoration: none;

    }

}
.menuIcon {

    margin-right: .5rem;

}

.profile {

    display: block;
    font-style: italic;
    margin-top: -5px;

}

</style>