<template>
    <div>

        <AppHeader />

        <router-view />

        <AppFooter />

    </div>
</template>

<script>

import AppHeader from '@/components/AppHeader/AppHeader'
import AppFooter from '@/components/AppFooter/AppFooter'

export default {
    
    name: 'MainTemplate',

    components: {
        AppHeader,
        AppFooter
    }

}

</script>

<style lang="scss" module>



</style>