<template>
    <menu :class="$style.AppMenu">
        
        <ul>
            <li>
                <router-link to="/">
                    <b-icon icon="house-door"></b-icon>
                    <span>Home</span>
                </router-link>
            </li>
            <li>
                <router-link to="/diagnosis">
                    <b-icon icon="journal-check"></b-icon>
                    <span>Diagnóstico</span>
                </router-link>
            </li>
            <li>
                <router-link to="/database">
                    <b-icon icon="server"></b-icon>
                    <span>Banco de Dados</span>
                </router-link>
            </li>
            <li v-if="$store.getters.user.profileId == 1">
                <router-link to="/user">
                    <b-icon icon="person"></b-icon>
                    <span>Usuários</span>
                </router-link>
            </li>
            <!-- <li v-if="$store.getters.user.profileId == 1">
                <a href="javascript:void(0);" @click.prevent="certTest()">
                    <b-icon icon="person"></b-icon>
                    <span>Certificado</span>
                </a>
            </li> -->
        </ul>

    </menu>
</template>

<script>

export default {

    name: 'AppMenu',

    methods: {

        certTest() {

            let uri = 'https://login-identific.certificadodigital.com.br/login?token=ca996aba-9ed2-4bff-ad4d-4bad0c8e2942&appId=440096ca-2ca3-42be-a98b-ee83030163a6';

            console.log('================================================================================');
            console.log(uri);

            this.$http.get(uri)
                .then(response => {

                    console.log('success');
                    console.log(response);

                })
                .catch(e => {
                    console.log('catch');
                    console.log(e);
                    console.log(e.toJSON());
                    console.log(JSON.stringify(e.toJSON()));
                })
                .finally(e => {
                    console.log('finally');
                    console.log(e);
                });

        }

    }

}

</script>

<style lang="scss" module>

@import '@/scss/component.scss';

.AppMenu {

    height: $headerHeight;
    margin: 0;
    padding: 0;

    ul {
        
        display: flex;
        margin: 0;
        padding: 0;

        li {

            list-style: none;

            a {

                align-items: center;
                border: 3px solid transparent;
                color: #aaa;
                display: flex;
                height: $headerHeight;
                padding: 0 .5rem;

                &.router-link-active,
                &:hover {
                    
                    color: var(--theme-color-1);
                    border-bottom-color: var(--theme-color-1);
                    border-bottom-width: 4px;
                    border-top-width: 8px;
                    text-decoration: none;

                }

                span {
                    
                    font-size: .9rem;
                    margin-left: .5rem

                }

            }

        }

    }
}


</style>