<template>
    <h1 :class="$style.appHeaderName">Talkture<span :class="$style.appHeaderNameSub"> Admin</span></h1>
</template>

<script>

export default {

    name: 'AppName'

}

</script>

<style lang="scss" module>

.appHeaderName {

    color: var(--theme-color-1);
    flex-grow: 1;
    font-family: var(--secundary-font);
    font-size: 2rem;
    font-weight: 900;
    line-height: 1;
    margin: 0;
    text-transform: uppercase;

}

.appHeaderNameSub {

    font-size: 1.5rem;
    font-style: italic;
    font-weight: 100;
    text-transform: capitalize;

}

</style>